<template>
<div>
    <v-container class="px-0">
        <v-col cols="12">
            <v-row>
                <v-col cols="12" lg="12" md="12">
                    <high-charts-drill-pie 
                        :id="1" 
                        :items="items"
                        :objDrilldown="objDrilldown"
                        :visibleSubTitle="true"
                        :subtitle="'REPORTE ANTIGUAMIENTO'"
                        :name="'Cuadrante'"
                        :valueSuffix="'USD'"
                    />
                </v-col>
            </v-row>
        </v-col>
    </v-container>
</div>
</template>

<script>
import HighChartsDrillPie from '@/components/Utils/HighCharts/HighChartsDrillPie.vue';
export default {
    props: {
        items: {
            type: Array,
            default: []
        },
        objDrilldown: {
            type: Array,
            default: []
        },
    },
    components: {
        HighChartsDrillPie
    },
    data() {
        return {

        };
    },
    methods: {},
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
