<template>
<div>

    <v-container>

        <v-row>
            <v-col cols="12">
                <s-toolbar :color="'#244093'" dark label="Reporte de Antiguamiento" class="tran"></s-toolbar>
                <v-row style="margin: auto">
                    <v-card width="100%">

                        <v-row style="margin: auto">
                            <v-col cols="4" lg="4" md="4">
                                <s-select-definition :def="1140" label="Estado" v-model="objState" return-object></s-select-definition>
                            </v-col>
                            <v-col cols="4" lg="2" md="2" class="mt-4">
                                <v-btn width="100%" rounded :color="'info'" small @click="run()">Buscar</v-btn>
                            </v-col>
                            <v-col cols="4" lg="2" md="2" class="mt-4">
                                <v-btn width="100%" rounded :color="'error'" small @click="downloadReport()">Descargar</v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
    <v-container>

        <v-tabs v-model="currentItem">
            <v-tab href="#Dashboard"> Dashboard</v-tab>
            <v-tab href="#ReportCustomer"> Reporte de Clientes</v-tab>
        </v-tabs>

        <v-tabs-items v-model="currentItem">
            <v-tab-item :value="'Dashboard'">
                <clc-report-antiquity-dashboard :items="dataDashboard" :objDrilldown="objDrilldown"></clc-report-antiquity-dashboard>
            </v-tab-item>
            <v-tab-item :value="'ReportCustomer'">
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-data-table :height="items.length > 0 ? '400px': 'auto'" :headers="headers" :items="items" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 100],
                    }">
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-col>
            </v-tab-item>

        </v-tabs-items>

        <v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
            <v-card color="primary" dark>
                <v-card-text v-if="messageProcessing.length == 0">
                    Por favor espere
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
                <v-card-text v-else>
                    <div class="text--white pt-4">
                        Error al realizar busqueda <br />
                        {{ messageProcessing }}
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</div>
</template>

<script>
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";
import ClcReportAntiquityDashboard from './ClcReportAntiquityDashboard.vue';

export default {
    components: {
        ClcReportAntiquityDashboard
    },
    data() {
        return {
            currentItem: "tab-Funciones",
            items: [],
            headers: [],
            report: {},

            processing: false,
            messageProcessing: "",
            objState: null,

            dataDashboard: [],
            objDrilldown: [],
            group: []
        }

    },
    methods: {

        run(){
            this.runViewDasboard();
            this.runView()

        },

        runViewDasboard() {
            var report={}
            report.QryNameProcedure = "";
            report.QryParamsArray = "StateName"
            report.QryParamsArrayValue = this.objState.DedDescription; //this.DateBegin + "," + this.DateEnd + "," + this.number;
            report.QryNameProcedure = "ClcReportAntiquaty_R_Dasboard";

            _sQryConfigurationService
                .querygeneral(report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {

                            var sum = 0,
                                objTmp = {},
                                objTmpdrilldown = {}

                            var UNOTREINTA = 0,
                                TRESUNOSESENTA = 0,
                                SESENTAUNONOVENTA = 0,
                                NOVENTAUNOCIENTOVEINTE = 0,
                                CIENTOVEINTEUNOCIENTOCINCUENTA = 0,
                                CIENTOCINCUENTACIENTOOCHENTA = 0,
                                CIENTOOCHENTATRESESENTA = 0,
                                TRESESENTAMAS = 0,
                                MENOSTRESSESENTAMAS = 0,
                                MENOSTRESSESENTACIENTOOCHENTAUNO = 0,
                                MENOSCIENTOOCHENTACIENTOCINCUENTAUNO = 0,
                                MENOSCIENTOCINCUENTACIENTOVEINTEUNO= 0,
                                MENOSCIENTOVEINTENOVENTAUNO= 0,
                                MENOSNOVENTASESENTAUNO= 0,
                                MENOSSESENTATREINTAUNO= 0,
                                MENOSTREINTACERO=0


                            this.dataDashboard = []
                            this.objDrilldown = []

                            this.group = _.groupBy(resp.data.filter(x => x.Cuadrante !== 'TOTALGENERAL'), "Cuadrante");

                            for (let prop in this.group) {
                                sum = 0,
                                    objTmp = {},
                                    objTmpdrilldown = {},
                                    UNOTREINTA = 0, TRESUNOSESENTA = 0, SESENTAUNONOVENTA = 0,
                                    NOVENTAUNOCIENTOVEINTE = 0, CIENTOVEINTEUNOCIENTOCINCUENTA = 0, CIENTOCINCUENTACIENTOOCHENTA = 0,
                                    CIENTOOCHENTATRESESENTA = 0, TRESESENTAMAS = 0

                                this.group[prop].map((i) => {
                                    sum = sum + i.TotalGeneral
                                });

                                objTmp.name = prop
                                objTmp.y = sum
                                objTmp.drilldown = prop

                                objTmpdrilldown.id = prop
                                objTmpdrilldown.name = prop
                                objTmpdrilldown.data = []

                                this.dataDashboard.push(objTmp)
                                this.objDrilldown.push(objTmpdrilldown)

                                this.group[prop].map((i) => {
                                    
                                    UNOTREINTA = UNOTREINTA + i.UNOTREINTA
                                    TRESUNOSESENTA = TRESUNOSESENTA + i.TRESUNOSESENTA
                                    SESENTAUNONOVENTA = SESENTAUNONOVENTA + i.SESENTAUNONOVENTA
                                    NOVENTAUNOCIENTOVEINTE = NOVENTAUNOCIENTOVEINTE + i.NOVENTAUNOCIENTOVEINTE
                                    CIENTOVEINTEUNOCIENTOCINCUENTA = CIENTOVEINTEUNOCIENTOCINCUENTA + i.CIENTOVEINTEUNOCIENTOCINCUENTA
                                    CIENTOCINCUENTACIENTOOCHENTA = CIENTOCINCUENTACIENTOOCHENTA + i.CIENTOCINCUENTACIENTOOCHENTA
                                    CIENTOOCHENTATRESESENTA = CIENTOOCHENTATRESESENTA + i.CIENTOOCHENTATRESESENTA
                                    TRESESENTAMAS = TRESESENTAMAS + i.TRESESENTAMAS

                                    MENOSTRESSESENTAMAS = MENOSTRESSESENTAMAS + i.MENOSTRESSESENTAMAS
                                    MENOSTRESSESENTACIENTOOCHENTAUNO = MENOSTRESSESENTACIENTOOCHENTAUNO + i.MENOSTRESSESENTACIENTOOCHENTAUNO
                                    MENOSCIENTOOCHENTACIENTOCINCUENTAUNO = MENOSCIENTOOCHENTACIENTOCINCUENTAUNO + i.MENOSCIENTOOCHENTACIENTOCINCUENTAUNO
                                    MENOSCIENTOCINCUENTACIENTOVEINTEUNO = MENOSCIENTOCINCUENTACIENTOVEINTEUNO + i.MENOSCIENTOCINCUENTACIENTOVEINTEUNO
                                    MENOSCIENTOVEINTENOVENTAUNO = MENOSCIENTOVEINTENOVENTAUNO + i.MENOSCIENTOVEINTENOVENTAUNO
                                    MENOSNOVENTASESENTAUNO = MENOSNOVENTASESENTAUNO+ i.MENOSNOVENTASESENTAUNO
                                    MENOSSESENTATREINTAUNO = MENOSSESENTATREINTAUNO + i.MENOSSESENTATREINTAUNO
                                    MENOSTREINTACERO = MENOSTREINTACERO + i.MENOSTREINTACERO

                                });

                                objTmpdrilldown.data.push(["[1 - 30]", UNOTREINTA])
                                objTmpdrilldown.data.push(["[31 - 60]", TRESUNOSESENTA])
                                objTmpdrilldown.data.push(["[61 - 90]", SESENTAUNONOVENTA])
                                objTmpdrilldown.data.push(["[91 - 120]", NOVENTAUNOCIENTOVEINTE])
                                objTmpdrilldown.data.push(["[121 - 150]", CIENTOVEINTEUNOCIENTOCINCUENTA])
                                objTmpdrilldown.data.push(["[151 - 180]", CIENTOCINCUENTACIENTOOCHENTA])
                                objTmpdrilldown.data.push(["[181 - 360]", CIENTOOCHENTATRESESENTA])
                                objTmpdrilldown.data.push(["[360 - MAS]", TRESESENTAMAS])


                                objTmpdrilldown.data.push(["[-360 a mas]", MENOSTRESSESENTAMAS])
                                objTmpdrilldown.data.push(["[-360 a -181]", MENOSTRESSESENTACIENTOOCHENTAUNO])
                                objTmpdrilldown.data.push(["[-180 a -151]", MENOSCIENTOOCHENTACIENTOCINCUENTAUNO])
                                objTmpdrilldown.data.push(["[-150 a -121]", MENOSCIENTOCINCUENTACIENTOVEINTEUNO])
                                objTmpdrilldown.data.push(["[-120 a -91]", MENOSCIENTOVEINTENOVENTAUNO])
                                objTmpdrilldown.data.push(["[-90 a -61]", MENOSNOVENTASESENTAUNO])
                                objTmpdrilldown.data.push(["[-60 a -31]", MENOSSESENTATREINTAUNO])
                                objTmpdrilldown.data.push(["[-30 a 0]", MENOSTREINTACERO])

                                
                                //objTmpdrilldown.data.push(["Largo", i.LARGO])

                            }
                        }
                    },
                    (e) => {

                    }
                );
        },

        runView() {
            var report = {}
            this.messageProcessing = ""
            report.QryNameProcedure = "";
            report.QryParamsArray = "StateName"
            report.QryParamsArrayValue = this.objState.DedDescription; //this.DateBegin + "," + this.DateEnd + "," + this.number;
            report.QryNameProcedure = "ClcReportAntiquaty_R";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.items = resp.data;

                            for (
                                var i = 0; i < Object.keys(this.items[0]).length; i++
                            ) {
                                this.headers.splice(i, i, {
                                    text: Object.keys(this.items[0])[i],
                                    value: Object.keys(this.items[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }

                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );

            this.runViewDasboard()
        },

        downloadReport() {
            this.messageProcessing = ""
            this.report.QryNameProcedure = "";
            this.report.QryParamsArray = "StateName"
            this.report.QryParamsArrayValue = this.objState.DedDescription; //this.DateBegin + "," + this.DateEnd + "," + this.number;
            this.report.QryNameProcedure = "ClcReportAntiquaty_R";

            this.processing = true;
            _sQryConfigurationService
                .downloadexcel(this.report, this.$fun.getUserID())
                .then(
                    (r) => {
                        if (r.status == 200) {
                            this.processing = false;

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.EXCEL,
                                "Reporte de Antiguamiento"
                            );
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        }
    }
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
